// File automatically generated via terraform, values are set in vault.prod-apsmos.com

export const environment = {
  production: true,
  baseUrl: 'https://demo-portal-cms.dev-apsmos.com',
  logout: 'https://demo-portal-cms.dev-apsmos.com/logout',
  graphqlUri: 'https://demo-portal-cms.dev-apsmos.com/graphql',
  keyCloakLogin: 'https://demo-portal-cms.dev-apsmos.com/connect/keycloak',
  strapiJwtEndpoint: 'https://demo-portal-cms.dev-apsmos.com/auth/keycloak/callback',
  cannyTokenEndpoint: 'https://demo-portal-cms.dev-apsmos.com/canny',
  sentryDSN: '',
  ssoLink: 'sso-test',
  useFreshStatusWidget: 'false',
  siteTitle: 'Demo Portal'
};
